import React, { Component } from 'react';

// vendor components
import { graphql, Link, StaticQuery, navigate } from 'gatsby';

// components
import CommonTeaser from 'components/common/teaser';
import PricingPlansModal from 'components/common/pricing-plans-modal';
import ExternableLink from '../../common/externable-link';

// constants
import { PRO_PLAN } from 'constants/servicebot_plans';
const DESCRIPTION_TEXT = `
   Join the Trove community to get access to this 
    video and more professional resources in The Trove Library. 
`;

// environment
const LOGIN_LINK = process.env.GATSBY_LOGIN_LINK;

// component class
class TeaserProLoginNeeded extends Component {
  state = {
    selectPlanModalData: null,
  };

  handleButtonClick = () => {
    const selectPlanModalData = {
      plan: PRO_PLAN,
      priceId: PRO_PLAN.prices[0].id,
    };

    this.setState({ selectPlanModalData });
  };

  handlePlanModalClose = () => {
    this.setState({ selectPlanModalData: null });
  };

  handlePlanSelected = priceId => {
    this.handlePlanModalClose();

    navigate(`/subscribe/checkout?payment_id=${priceId}`);
  };

  render() {
    const { data } = this.props;
    const { backgroundImage } = data;
    const { selectPlanModalData } = this.state;

    return (
      <>
        <CommonTeaser
          title="Want to view?"
          description={DESCRIPTION_TEXT}
          caption="Get Access"
          onClick={this.handleButtonClick}
          backgroundImage={(backgroundImage || {}).childImageSharp.fluid}
          hideGradient
        >
          Already signed up? Log in{' '}
          <ExternableLink
            className="text-white text-und"
            to={LOGIN_LINK || '/subscribe/login'}
            isExternal={!!LOGIN_LINK}
          >
            <u>here</u>
          </ExternableLink>
        </CommonTeaser>
        {!!selectPlanModalData && (
          <PricingPlansModal
            pricingPlans={selectPlanModalData.plan}
            activePriceId={selectPlanModalData.priceId}
            onClose={this.handlePlanModalClose}
            onSubmit={this.handlePlanSelected}
          />
        )}
      </>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        backgroundImage: file(relativePath: { eq: "teaser-1.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <TeaserProLoginNeeded data={data} {...props} />}
  />
);
