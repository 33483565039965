import React, { Component } from 'react';
import moment from 'moment';
import { graphql } from 'gatsby';

//lib
import ServiceBot from 'components/service-bot';

// components
import Layout from 'components/common/layout';
import Button from 'components/common/button';
import Header from 'components/common/header';
import QuestionForm from 'components/office-hours/question-form';
import ContentPlaceholder from 'components/office-hours/content-placeholder';
import TeaserProLoginNeeded from 'components/office-hours/teasers/teaser-pro-login-needed';
import TeaserProNeeded from 'components/office-hours/teasers/teaser-pro-needed';
import TeaserExpired from 'components/office-hours/teasers/teaser-expired';

// vendor components
import { Link } from 'gatsby';
import Img from 'gatsby-image';

// styles
import 'stylesheets/office-hours/index.scss';

// constants
import { PRO_PLAN } from '../constants/servicebot_plans';
const ACCESS_ALLOWED = 'ACCESS_ALLOWED';
const ACCESS_PRO_LOGIN_NEEDED = 'ACCESS_PRO_LOGIN_NEEDED';
const ACCESS_PRO_NEEDED = 'ACCESS_PRO_NEEDED';
const ACCESS_PRO_EXPIRED = 'ACCESS_PRO_EXPIRED';

class OfficeHoursPage extends Component {
  state = {
    isLoading: true,
    subscribeState: null,
  };

  componentDidMount() {
    ServiceBot.on('auth:change', this.handleSubscribedStateChange);

    this.handleSubscribedStateChange(ServiceBot.credentials);
  }

  componentWillUnmount() {
    ServiceBot.off('auth:change', this.handleSubscribedStateChange);
  }

  handleSubscribedStateChange = async token => {
    if (token === undefined) {
      return;
    }

    const subscribeState = this.getSubscribeState(token);

    this.setState({ subscribeState, isLoading: false });
  };

  getSubscribeState = authToken => {
    if (!authToken) {
      return ACCESS_PRO_LOGIN_NEEDED;
    }

    if (!ServiceBot.getIsSubscribeToPlan(authToken, [PRO_PLAN])) {
      return ACCESS_PRO_NEEDED;
    }

    if (!ServiceBot.getIsSubscribePaid(authToken)) {
      return ACCESS_PRO_EXPIRED;
    }

    return ACCESS_ALLOWED;
  };

  render() {
    const { data, pageContext } = this.props;
    const { isLoading, subscribeState } = this.state;

    if (isLoading) {
      return (
        <Layout>
          <Header styleType="dark" showLogo />
        </Layout>
      );
    }

    const { prevOfficeHours, nextOfficeHour } = pageContext || {};
    const { title, description, embedVideoCode, publishDate } =
      data.contentfulOfficeHour || {};
    const descriptionHtml = ((description || {}).childMarkdownRemark || {})
      .html;

    return (
      <Layout
        title="Office Hours"
        askNowPageIdentifier="office hours"
        askNowMobileOnly
        showAskNow
      >
        <Header styleType="dark" showLogo />
        <AccessOverlay subscribeState={subscribeState}>
          <main className="office-hours-page">
            <h1 className="office-hours-page_page-title">Office Hours</h1>

            <div className="d-flex flex-column flex-lg-row align-items-center align-items-lg-stretch">
              <div className="office-hours-page_main-content">
                {embedVideoCode && (
                  <div className="office-hours-page_video-wrap">
                    <div className="office-hours-page_video">
                      <iframe
                        title={title}
                        src={embedVideoCode}
                        frameBorder="0"
                        allowFullScreen
                      />
                    </div>
                  </div>
                )}
                <div className="office-hours-page_date">{publishDate}</div>
                <h2 className="office-hours-page_title">{title}</h2>

                <div
                  className="office-hours-page_description"
                  dangerouslySetInnerHTML={{ __html: descriptionHtml }}
                />

                <div className="d-none d-md-block">
                  <QuestionForm className="d-none d-md-flex" />
                </div>
              </div>

              <div className="office-hours-page_side-content">
                {nextOfficeHour && <ComingSoon officeHour={nextOfficeHour} />}
                <div className="office-hours-page_previous">
                  <h5 className="office-hours-page_previous_title">
                    Previous Office Hours
                  </h5>

                  {(prevOfficeHours || []).map(officeHour => (
                    <PreviousOfficeHour
                      key={officeHour.id}
                      officeHour={officeHour}
                    />
                  ))}
                </div>
              </div>
            </div>
          </main>
        </AccessOverlay>
      </Layout>
    );
  }
}

function AccessOverlay({ children, subscribeState }) {
  if (subscribeState === ACCESS_ALLOWED) {
    return children;
  }
  const teasers = {
    ACCESS_PRO_LOGIN_NEEDED: (
      <TeaserProLoginNeeded onChoosePlanClick={() => {}} />
    ),
    ACCESS_PRO_NEEDED: <TeaserProNeeded />,
    ACCESS_PRO_EXPIRED: <TeaserExpired />,
  };

  return (
    <>
      <div className="office-hours-page_access-denied-overlay">
        <div className="office-hours-page_backdrop" />
        <div className="office-hours-page_teaser-container">
          {teasers[subscribeState]}
        </div>
      </div>
      <ContentPlaceholder />
    </>
  );
}

function ComingSoon({ officeHour }) {
  const { title, description, publishDate, webinarLink } = officeHour || {};

  const descriptionExcerpt = ((description || {}).childMarkdownRemark || {})
    .excerpt;

  const currentDate = moment().startOf('day');
  const momentPublishDate = moment(publishDate).startOf('day');
  const untilRelease = momentPublishDate.diff(currentDate);
  const humanizedUntilRelease = moment.duration(untilRelease).humanize();
  const humanizedPublishDate = momentPublishDate.format('MMM DD, YYYY');
  const isAfter = momentPublishDate.isAfter();

  return (
    <div className="office-hours-page_coming-soon">
      <div className="office-hours-page_coming-soon_badge">coming soon</div>
      <h3 className="office-hours-page_coming-soon_title">{title}</h3>
      <p className="office-hours-page_coming-soon_description">
        {descriptionExcerpt}
      </p>

      <div className="d-flex flex-column flex-xl-row align-items-start">
        <div className="office-hours-page_coming-soon_date">
          {isAfter
            ? `Do you want to watch next webinar in ${humanizedUntilRelease} on ${humanizedPublishDate}?`
            : 'Do you want to watch next webinar today?'}
        </div>

        <Button
          useLink
          isExternalLink
          to={webinarLink}
          target="_blank"
          className="office-hours-page_side-content_button flex-shrink-0"
        >
          Register
        </Button>
      </div>
    </div>
  );
}

function PreviousOfficeHour({ officeHour }) {
  const {
    title,
    description,
    videoPreviewImage,
    slug,
    videoDuration,
    publishDate,
  } = officeHour || {};
  const descriptionExcerpt = ((description || {}).childMarkdownRemark || {})
    .excerpt;
  const truncatedTitle = title.length > 25 ? title.slice(0, 25) + '...' : title;

  const image = (videoPreviewImage || {}).fluid;
  const linkTo = `/office-hours/${slug}`;

  return (
    <Link to={linkTo} className="office-hours-page_previous_item">
      <div className="office-hours-page_previous_thumbnail-wrap">
        {videoDuration && (
          <div className="office-hours-page_previous_time">{videoDuration}</div>
        )}
        {image && (
          <Img className="office-hours-page_previous_thumbnail" fluid={image} />
        )}
      </div>
      <div className="d-flex flex-column">
        <h5 className="office-hours-page_previous_item-title">
          {truncatedTitle}
        </h5>
        <p className="office-hours-page_previous_description">
          {descriptionExcerpt}
        </p>
        <div className="office-hours-page_previous_date">{publishDate}</div>
      </div>
    </Link>
  );
}

export const pageQuery = graphql`
  query officeHourQuery($id: String) {
    contentfulOfficeHour(id: { eq: $id }) {
      id
      title
      embedVideoCode
      publishDate(formatString: "MM/DD/YYYY")
      description {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;

export default OfficeHoursPage;
