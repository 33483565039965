import React, { Component } from 'react';

// vendor components
import Textarea from 'react-textarea-autosize';
import Button from 'components/common/button';

// lib
import ActiveCampaignTracking from 'lib/active-campaign-tracking';
import ServiceBotProvider from 'components/service-bot';

// constants
import { ASKS_QUESTION_EVENT_NAME } from 'constants/active-campaign';

// styles
import './question-form.scss';

class OfficeHoursQuestionForm extends Component {
  state = {
    message: '',
    isSendError: false,
    isSendSuccess: false,
    isSendInProgress: false,
  };

  handleMessageChange = ({ target }) => {
    const { value } = target;

    this.setState({
      message: value,
    });
  };

  handleFormSubmit = async e => {
    e.preventDefault();
    const { message } = this.state;

    const authData = ServiceBotProvider.credentials;
    const currentUserEmail =
      (((authData || {}).data || {}).user || {}).email || '';

    if (!currentUserEmail) {
      return;
    }

    await this.sendQuestion({
      email: currentUserEmail,
      message,
    });
  };

  handleFormShow = () => {
    this.setState({
      isSendError: false,
      isSendSuccess: false,
    });
  };

  sendQuestion = async formData => {
    if (this.state.isSendInProgress) return null;

    this.setState({
      isSendInProgress: true,
      isSendError: false,
      isSendSuccess: false,
    });

    const result = await fetch(`/.netlify/functions/ask-now`, {
      method: 'POST',
      body: JSON.stringify(formData),
    });

    if (!result.ok) {
      this.setState({
        isSendInProgress: false,
        isSendError: true,
      });

      return null;
    }

    ActiveCampaignTracking.sendEvent(ASKS_QUESTION_EVENT_NAME);

    this.setState({
      isSendInProgress: false,
      isSendSuccess: true,
      message: '',
    });
  };

  render() {
    const {
      message,
      isSendError,
      isSendSuccess,
      isSendInProgress,
    } = this.state;

    if (isSendSuccess) {
      return (
        <div className="office-hours_question-send-result">
          <div>Your message has been successfully sent!</div>
          <Button
            onClick={this.handleFormShow}
            className="office-hours_question-send-result_button"
          >
            Ask one more Question
          </Button>
        </div>
      );
    }

    if (isSendError) {
      return (
        <div className="office-hours_question-send-result">
          <div>Something went wrong and your message wasn’t sent</div>
          <Button
            onClick={this.handleFormShow}
            className="office-hours_question-send-result_button"
          >
            Try again
          </Button>
        </div>
      );
    }

    return (
      <form
        className="office-hours_question-form"
        onSubmit={this.handleFormSubmit}
      >
        <h4 className="office-hours_question-form_title">Have a question?</h4>

        <Textarea
          placeholder="Submit your question here"
          className="office-hours_question-form_message-input"
          value={message}
          onChange={this.handleMessageChange}
        />

        <Button
          disabled={isSendInProgress}
          type="submit"
          className="office-hours_question-form_button"
        >
          Submit
        </Button>
      </form>
    );
  }
}

export default OfficeHoursQuestionForm;
