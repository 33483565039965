import React from 'react';

// components
import CommonTeaser from 'components/common/teaser';
import { graphql, StaticQuery } from 'gatsby';

// constants
const DESCRIPTION_TEXT = `
   Your payment method is invalid. Please update your billing settings.
`;

// component class
function TeaserExpired({ data }) {
  const { backgroundImage } = data;

  return (
    <CommonTeaser
      title="Want to view?"
      description={DESCRIPTION_TEXT}
      caption="UPDATE BILLING SETTINGS"
      to="/subscribe/profile"
      backgroundImage={(backgroundImage || {}).childImageSharp.fluid}
      hideGradient
    />
  );
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        backgroundImage: file(relativePath: { eq: "teaser-2.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <TeaserExpired data={data} {...props} />}
  />
);
