import React, { Component, createRef } from 'react';
import {graphql, StaticQuery} from "gatsby";

// vendor lib
import moment from 'moment';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

// vendor components
import Img from 'gatsby-image';

// components
import QuestionForm from 'components/office-hours/question-form';
import Button from 'components/common/button';

// constants
export const DATE_FORMAT = 'YYYY-MM-DD';

class OfficeHoursContentPlaceholder extends Component {
  rootRef = createRef();

  componentDidMount() {
    const rootElement = this.rootRef.current;

    disableBodyScroll(rootElement);
  }

  componentWillUnmount() {
    const rootElement = this.rootRef.current;

    enableBodyScroll(rootElement);
  }

  getplaceholderData() {
    const { data } = this.props;
    const placeholderImage2 = data.placeholderImage2.childImageSharp.fluid;
    const placeholderImage3 = data.placeholderImage3.childImageSharp.fluid;
    const placeholderImage4 = data.placeholderImage4.childImageSharp.fluid;
    const placeholderImage5 = data.placeholderImage5.childImageSharp.fluid;
    const placeholderImage6 = data.placeholderImage6.childImageSharp.fluid;
    const placeholderImage7 = data.placeholderImage7.childImageSharp.fluid;

    return {
      title: 'How To Defeat WordPress Malware For Good',
      description:
        'Our brain isn’t wired to perform more than one action thoroughly, especially when both actions involve using the same parts of the organ. That’s exactly why you can’t properly finish an email while being on the phone – your brain struggles with dedicating enough resources to each of these actions and eventually fails to do a decent job.',
      comingSoon: {
        title: 'An Insider Guide to Supercharging Your Pinterest Strategy',
        description:
          't is BuzzFeed’s second largest social network referrer. It also has a much longer lifecycle than other social networks, often driving...',
      },

      prevOfficeHours: [
        {
          title: 'Reasons Why Multitasking Is...',
          description:
            'Have an idea? Need us to cover a topic? Want to work with us? We...',
          duration: '45:12',
          image: placeholderImage2,
        },
        {
          title: '6 Gifting Trends For The Festive...',
          description:
            'Have an idea? Need us to cover a topic? Want to work with us? We...',
          duration: '45:12',
          image: placeholderImage3,
        },
        {
          title: 'Channel Your Creative Thinki...',
          description:
            'Have an idea? Need us to cover a topic? Want to work with us? We...',
          duration: '45:12',
          image: placeholderImage4,
        },
        {
          title: 'A Bloggers Guide to Black Friday...',
          description:
            'Have an idea? Need us to cover a topic? Want to work with us? We...',
          duration: '45:12',
          image: placeholderImage5,
        },
        {
          title: 'Timeless Boss Advice From 12 CD Babes',
          description:
            'Have an idea? Need us to cover a topic? Want to work with us? We...',
          duration: '45:12',
          image: placeholderImage6,
        },
        {
          title: 'Guide to Instagram Hashtags',
          description:
            'Have an idea? Need us to cover a topic? Want to work with us? We...',
          duration: '45:12',
          image: placeholderImage7,
        },
      ],
    };
  }

  render() {
    const { data } = this.props;
    const placeholderImage1 = data.placeholderImage1.childImageSharp.fluid;
    const currentDate = moment().format(DATE_FORMAT);
    const { title, description, prevOfficeHours, comingSoon } = this.getplaceholderData();

    return (
      <main
        ref={this.rootRef}
        className="office-hours-page office-hours-page--placeholder"
      >
        <h1 className="office-hours-page_page-title">Office Hours</h1>

        <div className="d-flex flex-column flex-lg-row align-items-center align-items-lg-stretch">
          <div className="office-hours-page_main-content">
            <div className="office-hours-page_video-wrap">
              <div className="office-hours-page_video office-hours-page_video--placeholder " >
                <Img fluid={placeholderImage1}/>
              </div>
            </div>
            <div className="office-hours-page_date">{currentDate}</div>
            <h2 className="office-hours-page_title">{title}</h2>

            <div className="office-hours-page_description">{description}</div>

            <div className="d-none d-md-block">
              <QuestionForm className="d-none d-md-flex" />
            </div>
          </div>

          <div className="office-hours-page_side-content">
            <ComingSoonPlaceholder comingSoonData={comingSoon} />
            <div className="office-hours-page_previous">
              <h5 className="office-hours-page_previous_title">
                Previous Office Hours
              </h5>

              {prevOfficeHours.map((data, index) => (
                <PreviousOfficeHourPlaceholder key={index} data={data} />
              ))}
            </div>
          </div>
        </div>
      </main>
    );
  }
}

function PreviousOfficeHourPlaceholder({ data }) {
  const { duration, title, description, image } = data;

  const currentDate = moment().format(DATE_FORMAT);

  return (
    <div className="office-hours-page_previous_item">
      <div className="office-hours-page_previous_thumbnail-wrap">
        <div className="office-hours-page_previous_time">{duration}</div>
        <Img className="office-hours-page_previous_thumbnail" fluid={image} />
      </div>
      <div className="d-flex flex-column">
        <h5 className="office-hours-page_previous_item-title">{title}</h5>
        <p className="office-hours-page_previous_description">{description}</p>
        <div className="office-hours-page_previous_date">{currentDate}</div>
      </div>
    </div>
  );
}

function ComingSoonPlaceholder({comingSoonData}) {
  const { description, title } = comingSoonData;

  return (
    <div className="office-hours-page_coming-soon">
      <div className="office-hours-page_coming-soon_badge">coming soon</div>
      <h3 className="office-hours-page_coming-soon_title">{title}</h3>
      <p className="office-hours-page_coming-soon_description">{description}</p>

      <div className="d-flex flex-column flex-xl-row align-items-start">
        <div className="office-hours-page_coming-soon_date">
          Do you want to watch next webinar in 6 days on Feb 02, 2019?
        </div>

        <Button className="office-hours-page_side-content_button flex-shrink-0">
          Register
        </Button>
      </div>
    </div>
  );
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage1: file(relativePath: { eq: "office-hours-placeholder-image-1.png" }) {
          childImageSharp {
            fluid(maxWidth: 713) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        placeholderImage2: file(relativePath: { eq: "office-hours-placeholder-image-2.png" }) {
          childImageSharp {
            fluid(maxWidth: 713) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        placeholderImage3: file(relativePath: { eq: "office-hours-placeholder-image-3.png" }) {
          childImageSharp {
            fluid(maxWidth: 713) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        placeholderImage4: file(relativePath: { eq: "office-hours-placeholder-image-4.png" }) {
          childImageSharp {
            fluid(maxWidth: 713) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        placeholderImage5: file(relativePath: { eq: "office-hours-placeholder-image-5.png" }) {
          childImageSharp {
            fluid(maxWidth: 713) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        placeholderImage6: file(relativePath: { eq: "office-hours-placeholder-image-6.png" }) {
          childImageSharp {
            fluid(maxWidth: 713) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        placeholderImage7: file(relativePath: { eq: "office-hours-placeholder-image-7.png" }) {
          childImageSharp {
            fluid(maxWidth: 713) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <OfficeHoursContentPlaceholder data={data} {...props} />}
  />
);
